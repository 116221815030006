<template>
  <b-card class="product-detail-card">
    <div class="row">
      <div class="col-lg-5 text-center">
        <b-carousel
          ref="productImageCarusel"
          v-model="image_index"
          class="w-100"
          controls
          :interval="50000"
        >
          <b-carousel-slide
            v-for="image in productImages"
            :key="image"
            :img-src="image"
          />
        </b-carousel>
        <div
          class="
            d-flex
            flex-wrap
            mt-2
            product-images-block
            justify-content-center
          "
        >
          <img
            v-for="(image, i) in productImages"
            :key="i"
            :src="image"
            :class="{ 'active-image': i === image_index }"
            alt="image"
            @click="selectImage(i)"
          >
        </div>
      </div>
      <div
        v-if="product"
        class="col-lg-7 pr-2 justify-content-center"
      >
        <h4 class="font-weight-bolder">
          {{ product.title }}
        </h4>
        <h5 class="text-muted mb-1">
          By <span class="font-weight-bolder">{{ product.manufacturer }}</span>
        </h5>
        <h5 class="text-muted mb-1">
          {{ $t("Available") }} -
          <span
            v-if="Boolean(product.status)"
            class="text-success"
          >{{
            $t("In Stock")
          }}</span>
          <span
            v-else
            class="text-danger"
          >{{ $t("Out of stock") }}</span>
        </h5>
        <!-- eslint-disable-next-line -->
        <h5 class="mb-1" v-html="product.body" />
        <div
          v-if="isOwnProduct && !isViewer"
          class="d-fle"
        >
          <b-button
            class="mt-2 mr-2"
            variant="primary"
            @click="changeStockStatus"
          >{{
            product.status ?
              $t("Mark as sold out"):
              $t("Mark as in stock")
          }}</b-button>
          <b-button
            class="mt-2"
            variant="outline-primary"
            @click="editProduct"
          >{{
            $t("Edit")
          }}</b-button>
        </div>
        <b-link
          v-else
          class="mt-2 btn btn-primary"
          @click.stop="markAsViewed"
        >
          {{ $t("Make inquiry") }}
        </b-link>
      </div>
    </div>
  </b-card>
</template>
<script>
import {
  BCard, BButton, BCarousel, BCarouselSlide, BLink,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BButton,
    BCarousel,
    BCarouselSlide,
    BLink,
  },
  data() {
    return {
      image_index: null,
    }
  },
  computed: {
    product() {
      return this.$store.getters[`${this.isOwnProduct ? 'myProducts' : 'marketplace'}/selectedProduct`]
    },
    productImages() {
      if (this.product) return [this.product.main_image, ...this.product.images]
      return []
    },
    isOwnProduct() {
      return this.$route.name === 'my_products'
    },
    user() {
      return this.$store.getters['auth/user']
    },
    isViewer() {
      return this.user.active_business_profile.role === 'viewer'
    },
  },
  created() {
    this.getProductById()
  },
  methods: {
    markAsViewed() {
      this.$store.dispatch('marketplace/markAsViewed', this.$route.params.id)
      window.location.href = `mailto:${this.product.creator.email}`
    },
    getProductById() {
      this.$store.dispatch(`${this.isOwnProduct ? 'myProducts' : 'marketplace'}/fetchProductById`, this.$route.params.id)
    },
    async changeStockStatus() {
      this.$store.dispatch('myProducts/changeStockStatus', { id: this.$route.params.id, status: this.product.status }).then(response => {
        if (response.status === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Your product has been successfully updated!'),
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.getProductById()
        }
      })
    },
    selectImage(index) {
      this.$refs.productImageCarusel.setSlide(index)
    },
    editProduct() {
      this.$router.push(`/my_products/update/${this.product.id}`)
    },
  },
}
</script>
